<template>
  <div :style="computedStyle" v-show="!isHidden">
    <span class="registry_fields_label xref_field_label" style="top: 0px">
      {{ label }}
      <el-tooltip v-if="tooltip" class="item" effect="dark" :content="tooltip">
        <i class="el-icon-question"></i>
      </el-tooltip>
    </span>
    <el-form-item
      :prop="name"
      :rules="[
      { required: _isRequired, message: $locale.main.message.required_field, trigger: 'blur' }
    ]">
      <AddressField
        :level="level"
        :placeholder="placeholder"
        :value="address"
        :required-level="requiredLevel"
        @input="$emit('input', $event)"
      ></AddressField>
    </el-form-item>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import AddressField from '@/core/infrastructure/components/AddressField'
import VisibleMixin from '../visible_properties_mixin'
import registryMixin from './registry_mixins'

export default {
  name: 'address_field',
  mixins: [mixin, VisibleMixin, registryMixin],
  components: {
    AddressField
  },
  props: {
    registry_properties: {
      type: Array,
      frozen: true
    },
    name: {
      type: String,
      frozen: true
    },
    label: {
      description: 'Название',
      type: String
    },
    placeholder: {
      description: 'Плейсхолдер',
      type: String
    },
    tooltip: {
      description: 'Подсказка',
      type: String
    },
    required: {
      description: 'Обязательное',
      type: Boolean
    },
    requiredLevel: {
      type: String,
      editor: 'Select',
      options: {
        options: [
          { id: 'subject', name: 'Регион' },
          { id: 'municipal_area', name: 'Район' },
          { id: 'city', name: 'Город' },
          { id: 'locality', name: 'Населенный пункт' },
          { id: 'planning_structure', name: 'Планировочная структура' },
          { id: 'street', name: 'Улица' },
          { id: 'house', name: 'Дом' },
          { id: 'flat', name: 'Помещение' }
        ]
      }
    }
  },
  async mounted () {
    this.level = (this.registry_properties.find((item) => item.id === 'address_level_id') || {}).value || null
    await this.parseValue()
  },
  data () {
    return {
      address: {},
      level: null
    }
  },
  methods: {
    async parseValue () {
      if (!this.value) {
        return false
      }
      let parsed = this.value
      try {
        parsed = JSON.parse(this.value)
      } catch (e) {
      }
      if (typeof parsed === 'object') {
        this.address = await this.getAddressById(parsed.id)
      } else {
        this.address = await this.getAddressById(parsed)
      }
    },
    async getAddressById (addressId) {
      let data = await this.$http.get(`${this.$config.api}/fiasaddressservice/addresses?id=${addressId}`)
      return data.data ? Object.assign(data.data[0], {
        name: data.data[0].address,
        address_id: data.data[0].id
      }) : {}
    }
  },
  computed: {
    computedStyle () {
      let css = this.CSS
      if (this.align) {
        css += ';text-align:' + this.align
      }
      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (this.width && !this.block) {
        css += ';width:' + this.width
      }
      if (!this.block) {
        css += `;display: inline-block; width:${this.width || '200px'}`
      }
      if (this.wrapper) {
        css += ';display: block;'
      }

      return css
    }
  }
}
</script>

<style scoped>

</style>
